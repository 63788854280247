import styled from 'styled-components';

const LoaderStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: initial;
`;
export default LoaderStyled;
